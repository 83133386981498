import React from 'react';
import Link from '../link';
import styles from './Button.module.css';
import { clsx } from 'clsx';
import Loading from './Loading';
import Tippy from '@tippyjs/react';

export interface props {
  size?: 'medium' | 'large';
  icon_align?: 'left' | 'right';
  loading?: boolean;
  label?: string;
  loadingClassName?: string;
  onClick?: () => void;
  className?: string;
  path?: string;
  typeBtn?: 'submit' | 'button' | 'reset' | undefined;
  type?: 'link';
  disabled?: boolean;
  variant?: VariantBtn;
  innerClassName?: string;
  iconClassName?: string;
  target?: '_blank' | '_parent' | '_self' | '_top';
  tooltipTitle?: string;
  showTooltip?: boolean;
  id?: string;
  download?: boolean;
}

const Button = ({
  size = 'large',
  icon_align = 'left',
  loading = false,
  className,
  loadingClassName,
  iconClassName,
  label,
  type,
  typeBtn = 'submit',
  path,
  disabled,
  variant = 'primary',
  onClick,
  innerClassName,
  target = '_self',
  tooltipTitle,
  showTooltip,
  id,
  download,
}: props) => {
  const left_icon = icon_align === 'left';
  const right_icon = icon_align === 'right';
  const linkType = type === 'link' && path;

  let isDisable = disabled || variant === 'disable';

  const handleClick = () => {
    if (onClick && !isDisable) onClick();
    else return null;
  };

  return (
    <>
      {linkType ? (
        <Link
          href={path}
          target={target}
          className={clsx(
            styles.btn,
            styles['variant--' + variant],
            styles[size],
            className
          )}
          download={download}
        >
          <div className={'flex items-center justify-center'}>
            {Boolean(iconClassName) && left_icon && (
              <i
                className={clsx(
                  'apollon-' + iconClassName,
                  'pr-2 md:pr-1 xl:pr-2',
                  styles['icon-size']
                )}
              />
            )}
            <span className={clsx(styles['btn--inner'], innerClassName)}>
              {label}
            </span>
            {Boolean(iconClassName) && right_icon && (
              <i
                className={clsx(
                  'apollon-' + iconClassName,
                  'pl-2 md:pl-1 xl:pl-2',
                  styles['icon-size']
                )}
              />
            )}
          </div>
        </Link>
      ) : (
        <Tippy
          disabled={!showTooltip}
          className='bg-primary text-center'
          content={tooltipTitle}
        >
          <button
            type={typeBtn}
            id={id}
            disabled={loading}
            className={clsx(
              styles.btn,
              styles[
                'variant--' +
                  (disabled || variant === 'disable' ? 'disable' : variant)
              ],
              styles[size],
              className
            )}
            onClick={handleClick}
          >
            {loading && <Loading variant={variant} />}
            <div
              className={clsx(
                styles['btn--inner'],
                innerClassName,
                loading && loadingClassName,
                loading && styles['loadingClassName']
              )}
            >
              {Boolean(iconClassName) && left_icon && !loading && (
                <i
                  className={clsx(
                    'apollon-' + iconClassName,
                    'pr-2',
                    styles['icon-size']
                  )}
                />
              )}
              {label}
              {Boolean(iconClassName) && right_icon && !loading && (
                <i
                  className={clsx(
                    'apollon-' + iconClassName,
                    'pl-2',
                    styles['icon-size']
                  )}
                />
              )}
            </div>
          </button>
        </Tippy>
      )}
    </>
  );
};
export default Button;
