import React from 'react';
import style from './Button.module.css';
import clsx from 'clsx';

const Loading = ({ variant }: { variant?: VariantBtn }) => {
  return (
    <div className={clsx(style['loading'], style['loading--' + variant])}></div>
  );
};

export default Loading;
