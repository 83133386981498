import React, { ReactNode } from 'react';
import { useModalContext } from 'common/context/modal-context';
import { Modal, ModalBody } from 'reactstrap';
import CloseBtn from './CloseModalBtn';
import style from './Style.module.css';
import clsx from 'clsx';
import Typography from 'common/components/tags/typography/Typography';

const Index = ({
  children,
  size,
  name,
  centered = false,
  className,
  title,
  closeButton = true,
  variantTitle = 'h4-card',
  classNameTitle,
  classNameHeader,
}: {
  children: ReactNode;
  size?: 'lg' | 'md' | 'xl' | 'sm' | 'sm-lg';
  name: string;
  title?: string;
  variantTitle?: string;
  centered?: boolean;
  classNameTitle?: string;
  closeButton?: boolean;
  className?: string;
  classNameHeader?: string;
}) => {
  const modalCtx = useModalContext();
  const isOpen = modalCtx?.modalName === name;

  return (
    <Modal size={size} isOpen={isOpen} centered={centered}>
      <div
        className='fixed top-0 right-0 left-0 bottom-0'
        onClick={() => modalCtx?.close()}
      />
      <ModalBody className={clsx(className, style['modal'])}>
        {(closeButton || title) && (
          <div
            className={clsx(
              'flex items-center justify-between',
              style['modal-header'],
              classNameHeader
            )}
          >
            <Typography variant={variantTitle} className={classNameTitle}>
              {title}
            </Typography>
            {closeButton && <CloseBtn />}
          </div>
        )}

        {children}
      </ModalBody>
    </Modal>
  );
};

export default Index;
