import React from 'react';
import clsx from 'clsx';
import style from './Style.module.css';

export enum Color {
  gray = 'gray',
  primary = 'primary',
}
export interface props {
  className?: string;
  color?: Color;
  containerClassName?: string;
}

const Spin = ({
  className,
  color = Color.primary,
  containerClassName,
}: props) => {
  return (
    <div className={containerClassName}>
      <div
        className={clsx(
          style['spin-loading'],
          style['item-' + color],
          className
        )}
      />
    </div>
  );
};

export default Spin;

