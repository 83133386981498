import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useModalContext } from 'common/context/modal-context';

const CloseModalBtn = () => {
  const modalCtx = useModalContext();

  return (
    <span
      className='inline-block cursor-pointer'
      onClick={() => modalCtx?.close()}
    >
      <MdOutlineClose color='#23274D' size={20} />
    </span>
  );
};

export default CloseModalBtn;
