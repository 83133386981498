import React from 'react';
import Link from 'common/components/tags/link';

export interface props {
  className?: string;
}
const Logo = ({ className }: props) => {
  return (
    <Link href='/' className={className}>
      <img src='/img/logo.svg' alt='logo' className='h-[3.5rem] w-[11.5rem]' />
    </Link>
  );
};

export default Logo;
