import clsx from 'clsx';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import style from './Style.module.css';

const Index = (props: {
  count?: number;
  circle?: boolean;
  className?: string;
  variant?: string;
  containerClassName?: string;
}) => {
  return (
    <Skeleton
      {...props}
      className={clsx(props.className, style['variant--' + props.variant])}
    />
  );
};

export default Index;
